// Función para calcular el promedio de tiempos
function calculateAverageTime(filteredData) {
  const total = filteredData.reduce((acc, [, time]) => acc + time, 0);
  return total / filteredData.length;
}

// Función para filtrar datos en un rango de tiempo específico
function filterDataByTimeRange(data, hoursAgo) {
  const currentTime = new Date();
  const startTime = new Date(currentTime.getTime() - hoursAgo * 60 * 60 * 1000);

  return data.filter(([dateString]) => new Date(dateString) >= startTime);
}

function renderAverageState(element, average){
  element.innerHTML = average.toFixed(1) + " segundos";
  const indicator = document.getElementById(element.id + "Indicator")

  indicator.classList.remove("bg-success", "bg-warning", "bg-danger")
  indicator.classList.remove("shadow-success", "shadow-warning", "shadow-danger")

  if (average > 0 && average <= 50){
    indicator.classList.add("bg-success", "shadow-success");
  }else if (average >= 51 && average<=80) {
    indicator.classList.add("bg-warning", "shadow-warning");
  }else {
    indicator.classList.add("bg-danger", "shadow-danger");
  }
}

function renderLastResult(element, result, executedAt){
  renderAverageState(element, result)

  const executedAtElement = document.getElementById("lastResultExecutedAt")
  executedAtElement.innerHTML = executedAt
}

function renderSignalIndicatorState(average){
  const signalIcon = document.querySelector("#signal");
  
  signalIcon.classList.remove('text-muted', 'text-success', 'text-warning', 'text-danger');

  if (average > 0 && average <= 50){
    signalIcon.classList.add('text-success');
  }else if (average >= 51 && average<=80) {
    signalIcon.classList.add('text-warning');
  }else {
    signalIcon.classList.add('text-danger');
  }
}

const statusMidt = () => {
  const signalIcon = document.querySelector("#signal");
  if (signalIcon) {
    let retryChartDataFetch = null

    function refreshData(){
      let chart = Chartkick.charts["midt-state"]

      if (chart === undefined) {
        console.log("chart es nulo...")
        clearInterval(retryChartDataFetch)
        return
      }

      if (chart.getData() === undefined){
        console.log("Error, no hay data")
        return
      }

      if (Array.from(chart.getData()[0]["data"]).length === 0) {
        console.log("reintentando obtencion de datos para el grafico...")
        chart.refreshData()
      }

      clearInterval(retryChartDataFetch)
      retryChartDataFetch = null
    }

    let reRenderInterval = setInterval(function () {
      try {
        let chart = Chartkick.charts["midt-state"]

        // verificacion que el chart exista
        if (chart === undefined) {
          console.log("chart es nulo...")
          clearInterval(reRenderInterval)
          clearInterval(retryChartDataFetch)
          return
        }

        if (chart.getData() === undefined){
          console.log("Error, no hay data")
          return
        }

        const data = chart.getData()[0]["data"]

        // verificacion que existan datos, si no hay se reintenta
        if (Array.from(data).length === 0  && retryChartDataFetch == null) {
          retryChartDataFetch = setInterval(refreshData, 10 * 100)
          return
        }

        const lastResultData = data[data.length - 1]

        // Calcular el promedio de la última hora
        const lastHourData = filterDataByTimeRange(data, 1);
        const averageLastHour = calculateAverageTime(lastHourData);

        // Calcular el promedio de las últimas tres horas
        const lastThreeHoursData = filterDataByTimeRange(data, 3);
        const averageLastThreeHours = calculateAverageTime(lastThreeHoursData);

        // Calcular el promedio del último día
        const lastDayData = filterDataByTimeRange(data, 24);
        const averageLastDay = calculateAverageTime(lastDayData);

        // actualizacion de indicadores
        renderSignalIndicatorState(averageLastHour)
        renderLastResult(document.querySelector("#lastResultSeconds"), lastResultData[1], lastResultData[0].toLocaleTimeString())
        renderAverageState(document.querySelector("#averageInLastHour"), averageLastHour)
        renderAverageState(document.querySelector("#averageInThreeHours"), averageLastThreeHours)
        renderAverageState(document.querySelector("#averageInLastDay"), averageLastDay)
      }catch (e){
        console.log("error, actualizando datos modal...")
      }
    }, 500);
  }
}

export { statusMidt };
